export const WORDS = [
  {
    "word": "AUCA",
    "definition": "Alianza UC App - Alianza’s flagship apps (desktop and mobile) for our BCC based Unified Communications offerings . “Alianza UC App” is the new name for the app formerly known as “Cymbus 2”."
  },
  {
    "word": "VoIP",
    "definition": "Voice over Internet Protocol - A technology that allows voice calls using a broadband Internet connection instead of a regular phone line."
  },
  {
    "word": "UCaaS",
    "definition": "Unified Communications as a Service - Cloud-based communication solutions that combine messaging, voice and video calls, and file sharing."
  },
  {
    "word": "CPaaS",
    "definition": "Communications Platform as a Service - A cloud-based platform that enables developers to add real-time communications features to their own applications without needing to build backend infrastructure and interfaces."
  },
  {
    "word": "POTS",
    "definition": "Plain Old Telephone Service - Traditional telephone service that transmits voice over copper twisted pair wires."
  },
  {
    "word": "TDM",
    "definition": "Time Division Multiplexing enables multiple telephone calls to share a single line by allocating different time slots to each call, thus increasing the line's capacity without physical expansion."
  },
  {
    "word": "SaaS",
    "definition": "Software as a Service - A software distribution model in which applications are hosted by a vendor or service provider and made available to customers over a network, typically the internet."
  },
  {
    "word": "SIP",
    "definition": "Session Initiation Protocol - A protocol for setting up, managing, and ending voice or video calls over the internet in telephone systems, enabling VoIP communications."
  },
  {
    "word": "DTMF",
    "definition": "Dual-Tone Multi-Frequency - The signal to the phone switch that you generate when you press an ordinary telephone's touch keys."
  },
  {
    "word": "PBX",
    "definition": "Private Branch Exchange - A private telephone network used within a company or organization, which can be connected to a SIP Trunk."
  },
  {
    "word": "IVR",
    "definition": "Interactive Voice Response - A technology that allows a computer to interact with humans through the use of voice and DTMF tones input via a keypad."
  },
  {
    "word": "QoS",
    "definition": "Quality of Service - The overall performance of a telephony or computer network, particularly the performance seen by the users of the network."
  },
  {
    "word": "ISDN",
    "definition": "Integrated Services Digital Network - A set of communication standards for simultaneous digital transmission of voice, video, data, and other network services over the traditional circuits of the public switched telephone network."
  },
  {
    "word": "MPLS",
    "definition": "Multiprotocol Label Switching - A type of data-carrying technique for high-performance telecommunications networks."
  },
  {
    "word": "VoLTE",
    "definition": "Voice over Long-Term Evolution - A standard for high-speed wireless communication for mobile phones and data terminals, including voice data."
  },
  {
    "word": "ZOOM",
    "definition": "A cloud-based video conferencing service you can use to virtually meet with others - either by video or audio-only or both."
  },
  {
    "word": "Teams",
    "definition": "Microsoft Teams - A collaboration app that helps your team stay organized and have conversations all in one place."
  },
  {
    "word": "Slack",
    "definition": "A messaging app for business that connects people to the information they need, enabling better decision making and more productive teamwork."
  },
  {
    "word": "WebEx",
    "definition": "Cisco's cloud-based collaboration platform, offering video conferencing, online meetings, and webinars, designed for business and remote communication."
  },
  {
    "word": "Codec",
    "definition": "A device or program that compresses data to enable faster transmission and decompresses received data. Some examples include G.711, G.729, and OPUS."
  },
  {
    "word": "NAT",
    "definition": "Network Address Translation - A method of remapping one IP address space into another by modifying network address information in the IP header of packets while they are in transit across a traffic routing device."
  },
  {
    "word": "RTP",
    "definition": "Real-time Transport Protocol - A protocol for delivering audio and video over IP networks."
  },
  {
    "word": "LAN",
    "definition": "Local Area Network - A network that connects computers and devices in a limited geographical area such as home, school, computer laboratory, or office building."
  },
  {
    "word": "WAN",
    "definition": "Wide Area Network - A telecommunications network that extends over a large geographical area for the primary purpose of computer networking."
  },
  {
    "word": "API",
    "definition": "Application Programming Interface - A set of routines, protocols, and tools for building software and applications, enabling different software systems to communicate with each other."
  },
  {
    "word": "HTTP",
    "definition": "Hypertext Transfer Protocol - An application protocol for distributed, collaborative, hypermedia information systems."
  },
  {
    "word": "HTTPS",
    "definition": "Hypertext Transfer Protocol Secure - An extension of HTTP for secure communication over a computer network."
  },
  {
    "word": "SSH",
    "definition": "Secure Shell - A cryptographic network protocol for operating network services securely over an unsecured network."
  },
  {
    "word": "TLS",
    "definition": "Transport Layer Security - A cryptographic protocol designed to provide communications security over a computer network."
  },
  {
    "word": "SRTP",
    "definition": "Secure Real-time Transport Protocol - An extension of RTP (Real-time Transport Protocol) that provides encryption, message authentication and integrity, and replay protection to the RTP data in both unicast and multicast applications."
  },
  {
    "word": "STUN",
    "definition": "Session Traversal Utilities for NAT - A protocol that enables an end host to discover its public IP address if it is located behind a NAT."
  },
  {
    "word": "TURN",
    "definition": "Traversal Using Relays around NAT - A protocol that allows for an element behind a NAT or firewall to receive incoming data over TCP or UDP."
  },
  {
    "word": "SBC",
    "definition": "Session Border Controller - A device used in some VoIP networks to exert control over the signaling and usually also the media streams involved in setting up, conducting, and tearing down calls."
  },
  {
    "word": "DID",
    "definition": "Direct Inward Dialing - A service of a local phone company (or local exchange carrier) that provides a block of telephone numbers for calling into a company's private branch exchange (PBX) system."
  },
  {
    "word": "IMS",
    "definition": "IP Multimedia Subsystem - An architectural framework for delivering IP multimedia services."
  },
  {
    "word": "MMS",
    "definition": "Multimedia Messaging Service - A standard way to send messages that include multimedia content to and from mobile phones."
  },
  {
    "word": "SMS",
    "definition": "Short Message Service - A text messaging service component of most telephone, internet, and mobile-device systems."
  },
  {
    "word": "LERG",
    "definition": "Local Exchange Routing Guide - A database used in the United States by telecommunications providers for call routing and billing purposes, containing information about the North American Numbering Plan (NANP) areas, rate centers, and numbering resources."
  },
  {
    "word": "NANPA",
    "definition": "North American Numbering Plan Administration - The organization responsible for the administration, assignment, and management of area codes and central office prefixes in the North American Numbering Plan."
  },
  {
    "word": "CNAM",
    "definition": "Caller Name - A feature in telecommunications where the name of the calling party is transmitted and displayed on the recipient's telephone display."
  },
  {
    "word": "LNP",
    "definition": "Local Number Portability - The ability of a telephone customer to retain their phone number if they switch to another telephone service provider."
  },
  {
    "word": "LRN",
    "definition": "Location Routing Number - A unique 10-digit number assigned to each switch or node in the network for routing calls in the telecommunications infrastructure."
  },
  {
    "word": "NPAC",
    "definition": "Number Portability Administration Center - The system that facilitates number portability in the telecommunications sector in the United States."
  },
  {
    "word": "NXX",
    "definition": "The central office code in the North American Numbering Plan, representing a specific exchange within an area code."
  },
  {
    "word": "OCN",
    "definition": "Operating Company Number - A code that uniquely identifies telecommunications carriers within the North American Numbering Plan."
  },
  {
    "word": "PIC",
    "definition": "Primary Interexchange Carrier - The telecommunications carrier that a customer has chosen to carry their long-distance calls."
  },
  {
    "word": "SLIC",
    "definition": "Subscriber Line Interface Circuit - A device in telecommunications that connects a telephone company's line into a home or business."
  },
  {
    "word": "Fiber",
    "definition": "Fiber Optics - A technology that uses glass or plastic threads (fibers) to transmit data. Fiber optic cables have a much greater bandwidth than metal cables, allowing them to carry more data."
  },
  {
    "word": "GPON",
    "definition": "Gigabit Passive Optical Network - A point-to-multipoint access mechanism using passive splitters in the fiber distribution network, enabling one single feeding fiber from the provider to serve multiple homes and small businesses."
  },
  {
    "word": "XGPON",
    "definition": "10 Gigabit Passive Optical Network - An advancement over GPON, offering significantly higher bandwidth up to 10 Gbps, designed to support next-generation broadband requirements."
  },
  {
    "word": "EPON",
    "definition": "Ethernet Passive Optical Network - A standard for using Ethernet for packet data over optical fiber access networks."
  },
  {
    "word": "FTTH",
    "definition": "Fiber to the Home - A delivery of a communications signal over optical fiber from the operator's switching equipment all the way to a home or business, thereby replacing existing copper infrastructure such as telephone wires and coaxial cable."
  },
  {
    "word": "DOCSIS",
    "definition": "Data Over Cable Service Interface Specification - An international telecommunications standard that permits the addition of high-bandwidth data transfer to an existing cable TV (CATV) system."
  },
  {
    "word": "PON",
    "definition": "Passive Optical Network - A fiber-optic telecommunications technology for delivering broadband network access to end-customers. Its key characteristic is the use of passive optical components such as splitters, which enable one single feeding fiber to serve multiple homes or locations."
  },
  {
    "word": "ONT",
    "definition": "Optical Network Terminal - A device that connects to the termination point of a provider's fiber optic cable and converts optical signals to electrical signals for use in a home or business."
  },
  {
    "word": "Voice",
    "definition": "Refers to the sound or the act of speaking by the user in a telecommunication context, often transmitted over a network."
  },
  {
    "word": "Phone",
    "definition": "A device that allows users to make and receive calls over a telecommunications network, traditionally using voice communication."
  },
  {
    "word": "Line",
    "definition": "A circuit or channel which is used for the transmission of voice, data, or video communications, often referring to a single telephone number."
  },
  {
    "word": "Port",
    "definition": "Either transferring a phone number from one carrier or service provider to another, a physical or virtual endpoint used for connecting devices or for routing calls and data."
  },
  {
    "word": "Call",
    "definition": "A telephone connection established between two or more parties to communicate."
  },
  {
    "word": "Ring",
    "definition": "The sound made by a telephone indicating an incoming call or message."
  },
  {
    "word": "Dial",
    "definition": "The act of entering a phone number to establish a phone call."
  },
  {
    "word": "Text",
    "definition": "Sending a written message via SMS (Short Message Service) over a phone network."
  },
  {
    "word": "Hold",
    "definition": "A feature that allows a caller to pause a conversation temporarily without ending the call. The held party will typically hear Hold Music until taken off hold."
  },
  {
    "word": "Mute",
    "definition": "A function that turns off the microphone, preventing the caller or meeting participant from being heard by other parties."
  },
  {
    "word": "Talk",
    "definition": "The act of communicating verbally over a phone."
  },
  {
    "word": "Tone",
    "definition": "A sound signal in telecommunication that indicates the status of a line (busy, ringing, etc.) or is used for dialing."
  },
  {
    "word": "Hang",
    "definition": "To end a phone call; short for 'hang up'."
  },
  {
    "word": "Roam",
    "definition": "The ability for a cell phone user to automatically make and receive voice calls, send and receive data, or access other services when traveling outside the geographical coverage area of the home network, by means of using a visited network."
  },
  {
    "word": "Data",
    "definition": "Information processed or stored by a computer, often used in the context of mobile phones to refer to internet service."
  },
  {
    "word": "ILEC",
    "definition": "Incumbent Local Exchange Carrier - A telephone company that was providing local service when the Telecommunications Act of 1996 was enacted, traditionally a regional monopoly."
  },
  {
    "word": "CLEC",
    "definition": "Competitive Local Exchange Carrier - A telecommunications provider company (sometimes called a carrier) that competes with other, already established carriers (usually an ILEC)."
  },
  {
    "word": "RLEC",
    "definition": "Rural Local Exchange Carrier - A smaller carrier that provides local exchange service in rural areas."
  },
  {
    "word": "RBOC",
    "definition": "Regional Bell Operating Company - One of the U.S. regional telephone companies that were formed from the breakup of AT&T in 1984."
  },
  {
    "word": "FOC",
    "definition": "Firm Order Commitment, in number porting, is the confirmed date set by the new carrier for completing a phone number's transfer from one carrier to another."
  },
  {
    "word": "CRD",
    "definition": "Customer Requested Due Date - The date requested by a customer or a customer's new service provider for services to be implemented or ported."
  },
  {
    "word": "LOA",
    "definition": "Letter of Authorization - A document authorizing a carrier to port a number away from another carrier. It typically must be signed by the end-user or authorized user of the numbers."
  },
  {
    "word": "SPID",
    "definition": "Service Provider ID - A unique identifier for each service provider involved in number portability."
  },
  {
    "word": "LRN",
    "definition": "Location Routing Number - A unique 10-digit number assigned to a switch for the purpose of routing calls in the North American Numbering Plan (NANP). It facilitates local number portability (LNP)."
  },
  {
    "word": "CLLI",
    "definition": "Common Language Location Identifier - A unique code that identifies telecommunications facilities and switches to facilitate interoperability and location identification."
  },
  {
    "word": "NPAC",
    "definition": "Number Portability Administration Center - The system responsible for facilitating number portability in the United States and Canada."
  },
  {
    "word": "SLC",
    "definition": "Subscriber Line Charge - A fee charged to subscribers or telephone users for the maintenance and connection of the line to the telephone network."
  },
  {
    "word": "CNAC",
    "definition": "Canadian Numbering Administration Consortium - The organization responsible for the administration of Canada's numbering resources, including area codes and special number blocks."
  },
  {
    "word": "CRTC",
    "definition": "Canadian Radio-television and Telecommunications Commission - The public authority that regulates and supervises broadcasting and telecommunications in Canada."
  },
  {
    "word": "CISC",
    "definition": "CRTC Interconnection Steering Committee - A committee that provides advice and makes recommendations to the CRTC on matters concerning the regulation and the efficiency of telecommunications services."
  },
  {
    "word": "LIR",
    "definition": "Local Interconnection Region - A geographic region within Canada defined for the purposes of telecommunications network interconnection and number portability."
  },
  {
    "word": "WISP",
    "definition": "Wireless Internet Service Provider - Offers internet access through wireless networking technologies, often used in rural areas where traditional broadband is not available."
  },
  {
    "word": "Cable",
    "definition": "Cable Internet - Delivers broadband internet access via the same coaxial cables that transmit cable television signals."
  },
  {
    "word": "DSL",
    "definition": "Digital Subscriber Line - Provides internet access through telephone lines, offering high-speed connectivity."
  },
  {
    "word": "FCC",
    "definition": "Federal Communications Commission - The United States government agency that regulates interstate and international communications by radio, television, wire, satellite, and cable."
  },
  {
    "word": "PUC",
    "definition": "Public Utility Commission - State agencies that regulate the rates and services of a public utility within a state or region in the United States."
  },
  {
    "word": "OCN",
    "definition": "Operating Company Number - A code that uniquely identifies telecommunications carriers within the North American Numbering Plan."
  },
  {
    "word": "VPC",
    "definition": "Amazon Virtual Private Cloud - A service that lets you launch AWS resources in a logically isolated virtual network that you define."
  },
  {
    "word": "IAM",
    "definition": "AWS Identity and Access Management - Enables you to manage access to AWS services and resources securely."
  },
  {
    "word": "RDS",
    "definition": "Amazon Relational Database Service - Makes it easy to set up, operate, and scale a relational database in the cloud."
  },
  {
    "word": "ELB",
    "definition": "Elastic Load Balancing - Automatically distributes incoming application traffic across multiple targets, such as Amazon EC2 instances, containers, and IP addresses."
  },
  {
    "word": "SNS",
    "definition": "Amazon Simple Notification Service - A managed service that provides message delivery from publishers to subscribers (also known as producers and consumers)."
  },
  {
    "word": "SQS",
    "definition": "Amazon Simple Queue Service - A fully managed message queuing service that enables you to decouple and scale microservices, distributed systems, and serverless applications."
  },
  {
    "word": "CDN",
    "definition": "Content Delivery Network - A system of distributed servers that deliver web content and other web services to users based on their geographic locations, the origin of the web content, and a content delivery server."
  },
  {
    "word": "CLI",
    "definition": "Command Line Interface - A text-based user interface used to view and manage computer files."
  },
  {
    "word": "SDK",
    "definition": "Software Development Kit - A collection of software development tools in one installable package, often used for developing applications for a specific device or operating system."
  },
  {
    "word": "Lambda",
    "definition": "AWS Lambda - A compute service that lets you run code without provisioning or managing servers, executing code only when needed and scaling automatically."
  },
  {
    "word": "Glue",
    "definition": "AWS Glue - A fully managed extract, transform, and load (ETL) service that makes it easy for customers to prepare and load their data for analytics."
  },
  {
    "word": "Host",
    "definition": "The user who initiates and controls a virtual meeting or collaboration session, managing participants and session settings."
  },
  {
    "word": "Meet",
    "definition": "A common term for a virtual meeting platform or the act of participating in a virtual meeting. Also a UCaaS product from Google."
  },
  {
    "word": "Blur",
    "definition": "A feature in video conferencing software that allows users to obscure their background to maintain privacy and reduce distractions."
  },
  {
    "word": "Join",
    "definition": "The action of entering a digital meeting or collaboration space, often by clicking a link or entering a meeting ID."
  },
  {
    "word": "Chat",
    "definition": "A feature allowing participants to send text messages to one another within a meeting or collaboration platform."
  },
  {
    "word": "Share",
    "definition": "The ability to present content, such as a screen or application window, to other participants in a virtual meeting."
  },
  {
    "word": "Poll",
    "definition": "A feature that enables the meeting host to create and share a questionnaire for participants to answer in real-time during the meeting."
  },
  {
    "word": "Room",
    "definition": "A virtual space within collaboration platforms where meetings are held or where teams can communicate and work together asynchronously."
  },
  {
    "word": "Link",
    "definition": "A URL or invitation used to access a virtual meeting or collaboration workspace."
  },
  {
    "word": "Sync",
    "definition": "Short for 'synchronize,' referring to the process of ensuring that meeting data and contacts are updated and consistent across all participants' devices."
  },
  {
    "word": "View",
    "definition": "The perspective or mode in which meeting content is displayed to participants, such as speaker view or gallery view."
  },
  {
    "word": "Team",
    "definition": "A group of individuals working together in a collaborative environment, often using digital tools to communicate and manage projects."
  },
  {
    "word": "Note",
    "definition": "A feature allowing participants to take and share written notes during a meeting or within a collaborative workspace."
  },
  {
    "word": "Poly",
    "definition": "Poly (formerly Plantronics and Polycom) is a global communications company that produces audio and video communication equipment for businesses and consumers, known for their headsets, video and audio conferencing devices, and collaboration solutions."
  },
  {
    "word": "Cisco",
    "definition": "Cisco Systems, Inc. is a multinational technology conglomerate headquartered in San Jose, California, that develops, manufactures, and sells networking hardware, software, telecommunications equipment, and other high-technology services and products."
  },
  {
    "word": "Joher",
    "definition": "JoHer is a French company that develops several VOIP products, including Voice Operator Panel. VOP is a professional softphone and attendant console for operators and receptionists."
  },
  {
    "word": "Cymbus",
    "definition": "Alianza's customer-facing brand of our business suite of applications. Service providers can take advantage of our Cymbus-branded marketing collateral and the Cymbus Help Center for end user support."
  },
  {
    "word": "Calix",
    "definition": "Calix, Inc. is a provider of cloud and software platforms, systems, and services to broadband service providers. They offer a popular ONT device that is used by many Alianza Service Providers."
  },
  {
    "word": "Arris",
    "definition": "Arris International Plc. is a telecommunications equipment manufacturing company that provides cable operators with high-speed data, video, and telephony systems for homes and businesses."
  },
  {
    "word": "Adtran",
    "definition": "ADTRAN, Inc. is a provider of networking and communications equipment, known for their solutions in fiber and broadband access, Ethernet and mobile backhaul, along with a suite of services for managing and deploying networks."
  },
  {
    "word": "Obihai",
    "definition": "Obihai Technology was a company specializing in VoIP hardware such as analog telephone adapters (ATA), known for allowing users to connect traditional telephones to digital voice over IP systems. It was acquired by Polycom, now part of Poly."
  },
  {
    "word": "Irby",
    "definition": "Irby is a company that may operate in various sectors, including utilities and construction. It's known for its supply chain services and solutions across different industries, potentially including telecommunications."
  },
  {
    "word": "Lumen",
    "definition": "Lumen Technologies (formerly CenturyLink) is a global telecommunications company providing a wide range of services including network, cloud, security, voice, and managed services designed to help meet the demand for a more connected world."
  },
  {
    "word": "Telus",
    "definition": "TELUS is a Canadian telecommunications company that offers a wide range of products and services including internet, voice, entertainment, healthcare, video, and IPTV television."
  },
  {
    "word": "Omni",
    "definition": "Omni Fiber is a telecommunications company that provides high-speed fiber internet services to residential and business customers, focusing on delivering reliable, fast internet connectivity with an emphasis on customer service and community engagement."
  },
  {
    "word": "Adams",
    "definition": "Adams Cable Service is a provider of cable television, broadband internet, and VOIP telephone services, primarily serving parts of Northeastern Pennsylvania and New York State."
  },
  {
    "word": "Ziply",
    "definition": "Ziply Fiber is a telecommunications company that offers fiber internet, phone, and TV services to residents and businesses in parts of Washington, Oregon, Idaho, and Montana."
  },
  {
    "word": "NAEC",
    "definition": "NAEC (North Arkansas Electric Cooperative) provides electric utility and telecommunications services such as broadband internet in specific areas."
  },
  {
    "word": "Ting",
    "definition": "Ting Internet is a growing fiber-to-the-home provider offering service in 23 cities and towns across 8 states."
  },
  {
    "word": "WEHCO",
    "definition": "WEHCO Media, Inc. is a diversified communications company involved in newspaper publishing, cable television, internet service, and telephone across various markets in Arkansas."
  },
  {
    "word": "Wyyerd",
    "definition": "Wyyerd is a fiber-to-the-home internet service provider offering high-speed internet, voice, and related services to communities in select areas, focusing on personalized local service."
  },
  {
    "word": "SECTV",
    "definition": "Service Electric Cable TV and Communications - one of the original cable television companies in the USA, providing cable TV, internet, and phone services in eastern Pennsylvania and western New Jersey."
  },
  {
    "word": "Viasat",
    "definition": "Viasat, Inc. is a global communications company that provides satellite broadband internet and other secure networking technologies and services to both commercial and government sectors."
  },
  {
    "word": "Xplore",
    "definition": "Xplornet Communications Inc., doing business as Xplore, is a Canadian rural internet service provider that offers broadband and satellite internet services to rural Canadian residents and businesses."
  },
  {
    "word": "Albion",
    "definition": "Albion Ventures LLC is a Technology Implementation and Application Development company, and is the provisioning and implementation partner for the Alianza Specialty Lines product."
  },
  {
    "word": "Telco",
    "definition": "Short for telecommunications company, a telco provides services such as voice, data, and video communication over various networks, including VOIP technology."
  },
  {
    "word": "Sales",
    "definition": "In the context of VOIP, sales refer to the process of selling VOIP services and products, including hardware like VOIP phones and software solutions, to businesses and consumers."
  },
  {
    "word": "CAPEX",
    "definition": "Capital Expenditure - Refers to the funds used by a telco to acquire, upgrade, and maintain physical assets such as network equipment, infrastructure for VOIP services, and other related technologies."
  },
  {
    "word": "Local",
    "definition": "Pertains to VOIP and telecommunications services provided within a specific geographic area or region, often contrasting with long-distance or international services."
  },
  {
    "word": "Legal",
    "definition": "In VoIP, legal considerations include compliance with regulations such as tariffs, taxes, and contributions to the Federal Universal Service Fund (FUSF), alongside adhering to local and international laws governing telecommunications, privacy, and data security."
  },
  {
    "word": "Rural",
    "definition": "Describes areas or communities located in the countryside or outside urban centers, where VOIP and telecommunications services might face challenges in deployment and accessibility."
  },
  {
    "word": "RDOF",
    "definition": "Rural Digital Opportunity Fund - A federal initiative aimed at expanding broadband access, including VOIP services, to rural areas and underserved communities in the United States."
  },
  {
    "word": "FUSF",
    "definition": "Federal Universal Service Fund - A U.S. government program designed to support universal access to telecommunications services, including VOIP, especially in rural, remote, and high-cost areas."
  },
  {
    "word": "Taxes",
    "definition": "Refers to the various local, state, and federal taxes and surcharges that apply to VOIP and telecommunications services, impacting both providers and consumers."
  },
  {
    "word": "Ditch",
    "definition": "Ditch the Switch refers to the act of replacing legacy phone services with Cloud technology, often for benefits like cost savings and enhanced features."
  },
  {
    "word": "Switch",
    "definition": "In VoIP, a Switch is a device or software in telecommunications that connects calls from one phone line to another, specifically designed for VoIP networks to route voice data packets between users."
  },
  {
    "word": "Agile",
    "definition": "A methodology adopted project management and software development, emphasizing flexibility, customer feedback, and rapid iteration."
  },
  {
    "word": "Chime",
    "definition": "Amazon Chime - A communication service by Amazon that provides video conferencing, calls, and chat, designed for businesses to facilitate remote collaboration and meetings. The service that powers Modern Meetings inside the Alianza UC App."
  },
  {
    "word": "Vfax",
    "definition": "Virtual Fax - A service that allows users to send and receive fax messages as digital documents via email or web applications, eliminating the need for traditional fax machines."
  },
  {
    "word": "Park",
    "definition": "Call Park - A feature in VOIP and telephony systems that allows a call to be placed on hold at one telephone set and continued from any other set in the system by dialing a code or selecting the parked call."
  },
  {
    "word": "SHAKEN",
    "definition": "Signature-based Handling of Asserted Information Using toKENs - Part of the STIR/SHAKEN protocol suite designed to combat caller ID spoofing on public telephone networks."
  },
  {
    "word": "Admin",
    "definition": "Administrator - This refers to a user with privileges to configure, manage, and maintain a system or platform, including setting up user accounts, call routing, and security measures."
  },
  {
    "word": "Layer",
    "definition": "Network Layer - This refers to the different layers of network protocols that work together to manage aspects of data communication, such as the transport layer for transmitting data and the application layer for VOIP services."
  },
  {
    "word": "Quote",
    "definition": "Service Quote - In telecommunications, a quote often refers to the estimated cost or pricing proposal provided by service providers for delivering communications solutions to businesses or individuals."
  },
  {
    "word": "Users",
    "definition": "End Users - The individuals or entities that utilize VOIP services for communication, including making and receiving calls, video conferencing, and messaging."
  },
  {
    "word": "Aging",
    "definition": "Telecommunications infrastructure, such as traditional PBX systems, becoming outdated or less efficient, prompting businesses to upgrade to modern cloud-based VOIP solutions for improved functionality and scalability."
  },
  {
    "word": "Email",
    "definition": "A method of exchanging digital messages over the Internet. In the context of VOIP, email integration allows voicemails and fax messages to be received and sent as email attachments, enhancing communication efficiency."
  },
  {
    "word": "Plans",
    "definition": "Calling plans determine which areas an end user can dial and are configurable by the service provider. Route plans determine which trunk calls are routed from, and are configurable by an Alianza user, and Feature Plans determine which features an End User has access to."
  },
  {
    "word": "Lines",
    "definition": "In VOIP, refers to virtual lines or channels that can be used simultaneously for making or receiving calls, as opposed to traditional telephony's physical lines connected to the public switched telephone network (PSTN)."
  },
  {
    "word": "Reset",
    "definition": "The process of changing a user's PIN or password to regain access to their account or resolve issues, often performed by an admin or automatically through a web interface for security purposes."
  },
  {
    "word": "Block",
    "definition": "A feature in VOIP systems that allows users to prevent incoming calls from specific phone numbers or domains, enhancing privacy and reducing unwanted communications."
  },
  {
    "word": "Night",
    "definition": "Night Forwarding - A VOIP feature that redirects calls to a predetermined number or voicemail outside of business hours, ensuring that important calls are not missed after hours."
  },
  {
    "word": "Audio",
    "definition": "Voice transmissions over the internet. High-quality audio codecs ensure clear communication without lag or distortion, critical for effective VOIP conversations."
  },
  {
    "word": "Apple",
    "definition": "A multinational technology company that develops and sells consumer electronics, software, and services, including iOS and macOS operating systems, which support applications like the AUCA app."
  },
  {
    "word": "Brand",
    "definition": "A service provider's brand can be customized and applied to the end-user portal and AUCA client, allowing for a tailored appearance and user experience that aligns with the provider's identity."
  },
  {
    "word": "Login",
    "definition": "The process by which users gain access to a portal or AUCA app by entering credentials, typically a username and password, to authenticate their identity and access personalized services."
  },
  {
    "word": "Digit",
    "definition": "In telecommunications, a single numerical value (0-9) used in dialing and configuring phone systems. Digits are fundamental in creating phone numbers, extension numbers, and access codes."
  },
  {
    "word": "Music",
    "definition": "Refers to the audio played to callers on hold or while waiting for a call to be answered, often used in auto attendants and prompt systems to improve the caller experience."
  },
  {
    "word": "Three",
    "definition": "Three-Way Calling - A feature in telecommunications that allows three parties to speak together on the same call, enhancing collaboration and communication efficiency."
  },
  {
    "word": "Offer",
    "definition": "In the context of telecommunications, refers to a package or plan proposed by service providers featuring a combination of services such as VOIP, internet, and cable TV, tailored to meet consumer or business needs."
  },
  {
    "word": "Refer",
    "definition": "In VOIP, a method used to redirect or transfer a call to another endpoint. The REFER method in SIP (Session Initiation Protocol) enables this function, facilitating call transfers without direct involvement of the initial call party."
  },
  {
    "word": "Rings",
    "definition": "The sound made by a telephone indicating an incoming call. In VOIP systems, the number of rings before a call is forwarded or sent to voicemail can often be customized."
  },
  {
    "word": "Error",
    "definition": "Any issue that disrupts normal service operations, such as connection failures, signal loss, or incorrect configuration settings, often requiring troubleshooting."
  },
  {
    "word": "Barge",
    "definition": "Barge-In - A feature in telecommunications that allows a third party, such as a supervisor in a contact center, to enter into an ongoing call, typically to assist or monitor the call for quality and training purposes."
  },
  {
    "word": "MacOS",
    "definition": "One of the operating systems developed by Apple, designed to run on Mac computers. macOS supports a wide range of applications, including the AUCA app."
  },
  {
    "word": "HIPAA",
    "definition": "The Health Insurance Portability and Accountability Act - U.S. legislation that provides data privacy and security provisions for safeguarding medical information. In telecommunications, compliance ensures that VOIP and UCaaS solutions protect sensitive health information during transmission."
  },
  {
    "word": "Agent",
    "definition": "In a contact center or customer service environment, an agent refers to an individual handling incoming or outgoing customer calls. Agents use telecommunications systems, to communicate with customers."
  },
  {
    "word": "Chats",
    "definition": "Text-based communications that occur in real-time between users over the internet. In UCaaS platforms, chats enhance collaboration by allowing quick messaging and file sharing among team members or with customers."
  },
  {
    "word": "Tiers",
    "definition": "Levels of service or support offered by telecommunications providers, often differentiated by the range of features, pricing, or priority support."
  },
  {
    "word": "CNAME",
    "definition": "Canonical Name Record - A type of DNS record that maps an alias name to a true or canonical domain name. CNAMEs are used in telecommunications to simplify the management of subdomains and are critical in configuring VOIP services and platforms."
  },
  {
    "word": "Avaya",
    "definition": "A global leader in business communications, offering a wide range of services including VOIP, contact center solutions, and unified communications as a service (UCaaS)."
  },
  {
    "word": "Mitel",
    "definition": "A telecommunications company that provides unified communications solutions for businesses, including VOIP phone systems, collaboration tools, and contact center technologies."
  },
  {
    "word": "Seats",
    "definition": "In the context of VOIP and UCaaS, seats refer to the number of users or licenses an organization purchases to access the service. It typically correlates with the number of employees or agents using the system."
  },
  {
    "word": "Fraud",
    "definition": "In telecommunications, fraud can refer to unauthorized use, breach, or manipulation of the system to make calls or access services without payment. VOIP systems require security measures to prevent such activities."
  },
  {
    "word": "Score",
    "definition": "A metric used in telecommunications to evaluate the quality of calls, customer satisfaction, or agent performance. For example, a MOS (Mean Opinion Score) measures the audio quality of a a VOIP call."
  },
  {
    "word": "Linux",
    "definition": "An open-source operating system widely used in server environments. Its stability, security, and flexibility make it a preferred choice for telecommunications infrastructure and application development."
  },
  {
    "word": "Java",
    "definition": "A high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible. Java is the primary programming language used at Alianza."
  },
  {
    "word": "Kairos",
    "definition": "Greek for “right time,” “season” or “opportunity”. The internal codename for a new softswitch built to provide advanced business services to Alianza customers, which resulted in the CPE2 platform and BCC product."
  },
  {
    "word": "GetFon",
    "definition": "A precursor to the Alianza we know and love today, GetFon delivered cloud-based VOIP telephony services in Latin America, and was sold to Alestra/AT&T in 2008."
  },
  {
    "word": "Portal",
    "definition": "A web-based interface that allows users and administrators to manage their telecommunications services, such as user accounts and billing information, providing centralized control and access."
  },
  {
    "word": "Router",
    "definition": "A networking device that forwards data packets between computer networks, crucial for VOIP systems as it directs voice traffic on the internet, ensuring efficient and quality communication."
  },
  {
    "word": "Online",
    "definition": "The state of being connected to the internet, enabling access to web-based telecommunications services, including VOIP and UCaaS platforms, for communication and collaboration."
  },
  {
    "word": "Analog",
    "definition": "Referring to a traditional method of transmitting voice signals over copper wires, contrasted with digital VOIP technologies that convert voice into digital data for transmission over the internet."
  },
  {
    "word": "BYOC",
    "definition": "Bring Your Own Carrier - A telecommunications model that allows businesses to select and bring their own PSTN Carrier contract for use with the Alianza platform."
  },
  {
    "word": "BeYOC",
    "definition": "Be Your Own Carrier - An approach where organizations manage their own telecommunications infrastructure and carrier services instead of using a carrier partner like Bandwidth or Sinch."
  },
  {
    "word": "BYOTN",
    "definition": "Bring Your Own Telephone Number - Similar to Be Your Own Carrier, but service providers maintain the contractual and operational ownership of TN management directly with Bandwidth."
  },
  {
    "word": "Media",
    "definition": "In telecommunications, media refers to the various forms of communication channels used for transmitting voice, video, data, and multimedia over a network, essential in VOIP and UCaaS solutions for rich interaction."
  },
  {
    "word": "ADPM",
    "definition": "Alianza Device Provisioning Manager - A pre-provisioning service where initial configuration is done to point a device to Alianza provisioning services, streamlining device setup for VOIP services. ADPM was chosen because it's easy to type on a phone keypad."
  },
  {
    "word": "ARPU",
    "definition": "Average Revenue Per User - A measure used by telecommunications providers to assess the revenue generated per user or unit, indicating the financial performance of the service offerings."
  },
  {
    "word": "ATA",
    "definition": "Analog Telephone Adapter - A device that connects traditional analog telephones to a digital or VOIP network, converting analog signals to digital data for transmission."
  },
  {
    "word": "ASH",
    "definition": "Refers to Alianza’s data center located in Ashburn, VA, which supports cloud communications services by providing robust and reliable data processing and storage capabilities."
  },
  {
    "word": "BCC",
    "definition": "Alianza’s Business Cloud Communications (BCC) is an all-in-one voice, communication, and collaboration solution that enables teams to talk, chat, meet, and share across their devices."
  },
  {
    "word": "BSS",
    "definition": "Business Support Systems - The components that a telecommunications service provider uses to run its business operations towards customers, covering billing, customer relationship management, and order management."
  },
  {
    "word": "BTN",
    "definition": "Billing Telephone Number - The primary telephone number associated with the billing account for a customer in telecommunications services."
  },
  {
    "word": "BLF",
    "definition": "Busy Lamp Field - A feature on VOIP telephones that indicates the status (e.g., available, busy, or on a call) of other users by lighting up a dedicated button or lamp."
  },
  {
    "word": "CDR",
    "definition": "Call Detail Record - A data record produced by a telephone exchange or other telecommunications equipment documenting the details of a telephone call or other telecommunications transaction."
  },
  {
    "word": "CPE",
    "definition": "Customer Premises Equipment OR Call Processing Engine - Devices or equipment at the customer's premises connecting to the telecommunications provider's network, such as modems, routers, and VOIP phones."
  },
  {
    "word": "CSR",
    "definition": "Customer Service Record - A document or electronic file that contains detailed information about a customer's telecommunications service, including account details, services subscribed, and billing information."
  },
  {
    "word": "DECT",
    "definition": "Digital Enhanced Cordless Telecommunications - A standard for cordless telephones, commonly used for home and business environments, compatible with VOIP systems for wireless communication."
  },
  {
    "word": "CPBX",
    "definition": "Cloud PBX - A virtual PBX system hosted in the cloud, and the name of Alianza's legacy business product. Cloud PBX offers advanced communication features without the need for on-site hardware, leveraging VOIP technology for call routing and management."
  },
  {
    "word": "JASS",
    "definition": "Java Alianza Soft Switch - One of the original names for the Alianza platform, now otherwise known as CPE1 or LignUp. Jass Integration is an internal service that is crucial for that platform."
  },
  {
    "word": "Vanity",
    "definition": "Any Telephone Number That Would Require Special Customization Of Numbers Or Letters When Assigning Or Registering A Telephone Number With The Number Porting Administration Center (NPAC) Or A Provider's Public Switched Telephone Network. Vanity numbers are often used for marketing purposes, making phone numbers easy to remember or recognize."
  },
  {
    "word": "TUI",
    "definition": "Telephone User Interface - Allows users to interact with a system via their telephone handset. Voicemail and Auto Attendant are examples of services that have a TUI."
  },
  {
    "word": "ZTP",
    "definition": "Zero Touch Provisioning - A method in telecommunications that automatically configures devices like and IP phones for network operation upon connection, minimizing manual setup processes."
  },
  {
    "word": "CALEA",
    "definition": "The Communications Assistance for Law Enforcement Act - U.S. legislation requiring telecommunications carriers and manufacturers of telecommunications equipment to modify and design their equipment, facilities, and services to ensure they have built-in surveillance capabilities, allowing federal agencies to wiretap any telephone or Internet communications."
  },
  {
    "word": "CCaaS",
    "definition": "Contact Center as a Service - A cloud-based solution that allows companies to utilize contact center software from a provider, offering scalability and flexibility in managing customer communications without the need for on-premises hardware."
  },
  {
    "word": "CNAM",
    "definition": "Caller Name - A feature in telecommunications that displays the caller's name on the recipient's phone, using a database to match the phone number with a name for identification."
  },
  {
    "word": "CRUD",
    "definition": "An acronym for Create, Read, Update, Delete - Fundamental operations of persistent storage in software development, including databases used in telecommunications for managing user data, configurations, and more."
  },
  {
    "word": "MACD",
    "definition": "Moves, Adds, Changes, Deletes - Referring to the modification or updating of user services and equipment configurations by the Service Provider."
  },
  {
    "word": "Device",
    "definition": "In telecommunications, a device refers to any hardware used for communication purposes, such as IP phones, modems, or routers, that connects to a network for voice, video, or data transmission."
  },
  {
    "word": "Config",
    "definition": "Short for 'configuration,' it refers to the setup parameters and settings of telecommunications equipment and software, defining how devices operate within a network or system."
  },
  {
    "word": "Caller",
    "definition": "The party who initiates a call in a telecommunications context, attempting to establish a connection with one or more recipients through voice, video, or text communication."
  },
  {
    "word": "Invite",
    "definition": "In SIP (Session Initiation Protocol), an INVITE request is used to initiate a session for voice or video calls, signaling the recipient to join a communication session."
  },
  {
    "word": "Notify",
    "definition": "A SIP (Session Initiation Protocol) method used to inform subscribers of changes in state or information within a communication session, part of event notification mechanisms in VOIP systems."
  },
  {
    "word": "Oracle",
    "definition": "A multinational computer technology corporation and developer of Acme Packet SBC (Session Border Controller), which is used by Alianza's Voice Platform."
  },
  {
    "word": "PSTN",
    "definition": "Public Switched Telephone Network - The traditional circuit-switched telephone network that provides the infrastructure and services for public telecommunication."
  },
  {
    "word": "Miss",
    "definition": "To not answer an incoming call, often resulting in a missed call notification for the recipient."
  },
  {
    "word": "WiFi",
    "definition": "A technology that allows electronic devices to connect to a wireless LAN (WLAN) network, often used for internet access within a building or localized area."
  },
  {
    "word": "Cell",
    "definition": "Short for 'cellular', relating to the mobile phone system, network, or a mobile phone itself (cellphone)."
  },
  {
    "word": "App",
    "definition": "Short for 'application', a software program designed to run on a mobile device, often used for various functions including communication, entertainment, and productivity."
  },
  {
    "word": "Code",
    "definition": "Written instructions that a computer can read and execute, typically written in a programming language like Java or Python."
  },
  {
    "word": "JSON",
    "definition": "JavaScript Object Notation - A lightweight data-interchange format that is easy for humans to read and write, and easy for machines to parse and generate."
  },
  {
    "word": "Node",
    "definition": "A basic unit used in computer science that can represent various objects in a network, or a computing environment that executes JavaScript code server-side."
  },
  {
    "word": "Git",
    "definition": "A distributed version control system used for tracking changes in source code during software development."
  },
  {
    "word": "REST",
    "definition": "Representational State Transfer - An architectural style for designing networked applications, relying on a stateless, client-server communication model."
  },
  {
    "word": "SQL",
    "definition": "Structured Query Language - A standardized programming language used for managing and manipulating relational databases."
  },
  {
    "word": "Bash",
    "definition": "Bourne Again SHell - A Unix shell and command language, and the default shell for Linux and macOS."
  },
  {
    "word": "Docker",
    "definition": "An open platform for developing, shipping, and running applications in containers, facilitating consistency across development and production environments."
  },
  {
    "word": "Cache",
    "definition": "A hardware or software component that stores data so that future requests for that data can be served faster."
  },
  {
    "word": "Brian",
    "definition": "Brian Beutler is Aliana's Founder, CEO, and Fearless Leader."
  },
  {
    "word": "Ryan",
    "definition": "One of the most common names for an employee at Alianza. Some famous examples include Higgins, Robison, Werner, and Nelson."
  },
  {
    "word": "Kevin",
    "definition": "In olden times, one of the most common names for an employee at Alianza. Now this name is reserved only for KD and nobody else is allowed to have it."
  },
  {
    "word": "cURL",
    "definition": "A command-line tool and library for transferring data with URLs. It is used in command lines or scripts to transfer data."
  },
  {
    "word": "VLAN",
    "definition": "Virtual Local Area Network - A network configuration that enables groups of devices on separate physical local area networks (LANs) to communicate as if they were on the same LAN."
  },
  {
    "word": "Edge",
    "definition": "Alianza Edge is a new product that brings the cloud to the customer's DC. A crucial component for LEC customers. Also a model of Poly phone."
  },
  {
    "word": "Busy",
    "definition": "A status indicating that a phone line or communication channel is currently in use and cannot accept additional calls at the moment. In VOIP systems, a busy signal or tone is sent to the caller."
  },
  {
    "word": "Answer",
    "definition": "The act of accepting an incoming call or communication request in telecommunications systems, establishing a connection between the caller and the recipient."
  },
  {
    "word": "Save",
    "definition": "To store data, such as contact information, call logs, or configuration settings, within a telecommunications system or device for future use."
  },
  {
    "word": "Center",
    "definition": "Alianza's Contact Center offers a cutting-edge solution for enhancing customer experience with features like skills-based routing, custom reporting, and workforce scheduling."
  },
  {
    "word": "Queue",
    "definition": "A line or sequence of calls or messages waiting to be processed or answered in a telecommunications system, often managed by software to ensure efficient handling."
  },
  {
    "word": "Active",
    "definition": "A status indicating that a device, service, or user is currently engaged in a telecommunications session or that a feature is in use."
  },
  {
    "word": "Costs",
    "definition": "Expenses associated with the implementation, operation, and maintenance of telecommunications systems, including VOIP and UCaaS platforms."
  },
  {
    "word": "Model",
    "definition": "A representation or framework of how telecommunications systems are structured and operate, often used in planning and deploying network architectures."
  },
  {
    "word": "Build",
    "definition": "The process of constructing or assembling telecommunications infrastructure or developing software solutions for VOIP and UCaaS services."
  },
  {
    "word": "Setup",
    "definition": "The configuration and installation process of telecommunications equipment and software, preparing them for operational use."
  },
  {
    "word": "Tools",
    "definition": "Software applications and hardware devices used to support, manage, and optimize telecommunications services, including VOIP and UCaaS platforms."
  },
  {
    "word": "Calls",
    "definition": "Voice communications transmitted over telecommunications networks, including traditional telephony and VOIP services."
  },
  {
    "word": "Needs",
    "definition": "Requirements or demands of businesses and users for telecommunications services, shaping the features and capabilities of VOIP and UCaaS solutions."
  },
  {
    "word": "Suite",
    "definition": "A comprehensive set of related telecommunications services and tools offered as a package, enhancing communication and collaboration capabilities."
  },
  {
    "word": "Video",
    "definition": "The transmission of moving visual images via telecommunications networks, an integral feature of modern UCaaS platforms."
  },
  {
    "word": "Trunk",
    "definition": "A communication line or link designed to carry multiple signals simultaneously, connecting switching centers in a telecommunications system."
  },
  {
    "word": "Churn",
    "definition": "The rate at which customers discontinue their subscription to a service. In telecommunications, it measures customer retention and satisfaction."
  },
  {
    "word": "Peace",
    "definition": "The feeling you feel when you make the switch to Alianza!"
  },
  {
    "word": "Phase",
    "definition": "A stage in the development or deployment cycle of telecommunications technologies, including testing, launch, and optimization."
  },
  {
    "word": "Fixed",
    "definition": "Refers to stationary telecommunications services, such as landline phones, including fixed E911."
  },
  {
    "word": "Hosts",
    "definition": "Servers or systems that provide infrastructure and services for telecommunications networks, including VOIP and UCaaS platforms."
  },
  {
    "word": "Proxy",
    "definition": "An intermediary device or server that relays requests and responses between a client and the destination server, often used in network security and efficiency."
  },
  {
    "word": "Record",
    "definition": "The action or feature of capturing and storing voice communications, such as call recording in VOIP systems for monitoring or compliance purposes."
  },
  {
    "word": "Paging",
    "definition": "A feature allowing one-way communication to multiple endpoints within a telecommunications system, commonly used in public address and intercom systems."
  },
  {
    "word": "Group",
    "definition": "A collection of users or devices within a telecommunications system that share common settings, permissions, or functionalities."
  },
  {
    "word": "Alpha",
    "definition": "An early development stage of software or technology projects, including telecommunications systems, where features are tested, but stability is not guaranteed."
  },
  {
    "word": "Beta",
    "definition": "The phase of software development in telecommunications where the product is more stable than alpha, but may still contain bugs and is released to a limited audience for testing."
  },
  {
    "word": "Screen",
    "definition": "Call Screening - A feature in telecommunications and VOIP systems that allows users to view incoming call information and decide how to handle it, such as answering, rejecting, or routing the call, enhancing control over incoming communications."
  },  
  {
    "word": "Retry",
    "definition": "The process of attempting a telecommunications transaction or connection again after a failure or error to establish communication."
  },
  {
    "word": "Legacy",
    "definition": "Referring to older telecommunications technologies or systems that are still in use but may not support modern functionalities and standards."
  },
  {
    "word": "Reject",
    "definition": "The action or signal in telecommunications to decline an incoming call, connection request, or Port request."
  },
  {
    "word": "Create",
    "definition": "To establish a new entity, such as a user account or communication channel, within a telecommunications system."
  },
  {
    "word": "Update",
    "definition": "The process of refreshing or upgrading telecommunications software or systems to improve performance, add features, or enhance security."
  },
  {
    "word": "Report",
    "definition": "A summary or detailed account of telecommunications system performance, usage statistics, or diagnostic results, used for analysis and decision-making."
  },
  {
    "word": "Hertz",
    "definition": "A unit of frequency equal to one cycle per second, used to measure the bandwidth or signal frequency in telecommunications."
  },
  {
    "word": "Noise",
    "definition": "Unwanted or disruptive sounds or signals that interfere with the transmission and reception of clear telecommunications."
  },
  {
    "word": "Speex",
    "definition": "An open-source, patent-free audio compression format designed for speech, often used in VOIP applications for efficient, high-quality voice transmission."
  },
  {
    "word": "Wave",
    "definition": "A digital audio file format that is used for storing waveform data; in telecommunications, it might refer to audio signals or sound files used in systems."
  },
  {
    "word": "Relay",
    "definition": "A network device or service that forwards data packets between networks, improving communication reach and efficiency in telecommunications systems."
  },
  {
    "word": "Blind",
    "definition": "A type of call transfer in telecommunications where a call is forwarded to another party without confirming if the end party is available or has answered."
  },
  {
    "word": "Cause",
    "definition": "In telecommunications, it refers to the reason or trigger for an event or action within the network, such as a call drop or connection failure."
  },
  {
    "word": "Guest",
    "definition": "A temporary or unregistered user in telecommunications systems, especially in UCaaS platforms, who can access certain services or participate in calls/meetings without full account privileges."
  },
  {
    "word": "Faxes",
    "definition": "Electronic documents transmitted over telecommunications networks using facsimile technology, which is still used in business communications despite the prevalence of digital messaging."
  },
  {
    "word": "Valet",
    "definition": "Valet Call Park - A feature enabling users to hold calls at a designated spot and retrieve them from any extension. The system automatically assigns a numbered spot for parked calls, accessible to anyone with the location number."
  },
  {
    "word": "Round",
    "definition": "Round Robin Call Distribution - A method used in telecommunications to evenly distribute incoming calls among available agents or lines, ensuring a balanced workload by rotating through a predefined list in sequential order."
  },
  {
    "word": "Robin",
    "definition": "Round Robin Call Distribution - A method used in telecommunications to evenly distribute incoming calls among available agents or lines, ensuring a balanced workload by rotating through a predefined list in sequential order."
  },
  {
    "word": "Rates",
    "definition": "The pricing or cost per unit of service in telecommunications, such as per-minute charges for calls or data usage fees."
  },
  {
    "word": "Store",
    "definition": "To save or archive telecommunications data, such as call logs, messages, or configuration settings, for future access or analysis."
  },
  {
    "word": "Touch",
    "definition": "Touch Tone Phone - A telephone that uses a system of signal tones generated by pressing buttons. This technology replaced rotary dial phones, allowing for direct dialing and faster call setup."
  },
  {
    "word": "Pulse",
    "definition": "A method used in older telecommunications systems for dialing where pulses of varying lengths represent different numbers."
  },
  {
    "word": "Share",
    "definition": "The act of distributing or making telecommunications resources, such as lines or bandwidth, available to multiple users or devices."
  },
  {
    "word": "Trace",
    "definition": "A diagnostic tool or process in telecommunications for tracking and analyzing the path of data packets to troubleshoot or optimize network performance."
  },
  {
    "word": "Hours",
    "definition": "Designated times during which certain telecommunications services or support may be available or operational, often differing between business and off-hours."
  },
  {
    "word": "Cross",
    "definition": "Cross-Site Call - Refers to telecommunications calls that are seamlessly handled across multiple data centers, ensuring uninterrupted service and connectivity."
  },  
  {
    "word": "Site",
    "definition": "A physical location or virtual platform in telecommunications where services are deployed or accessed, such as a data center or web portal."
  },
  {
    "word": "Allow",
    "definition": "To enable or permit specific actions, services, or traffic types in telecommunications systems, often controlled through configurations or policies."
  },
  {
    "word": "Types",
    "definition": "Admin Types: For organizational flexibility, there are five types of Admin users with varying levels of access."
  },
  {
    "word": "Bell",
    "definition": "Historically associated with Alexander Graham Bell, the inventor of the telephone, often used in the names of telecommunications companies."
  },
  {
    "word": "Qwest",
    "definition": "A large telecommunications carrier in the United States, known for providing voice, internet, and data services. It merged with CenturyLink in 2011."
  },
  {
    "word": "Nokia",
    "definition": "A multinational telecommunications, information technology, and consumer electronics company, known for its contributions to mobile and wireless communications."
  },
  {
    "word": "Micro",
    "definition": "Referring to microservices, a software architecture style that structures an application as a collection of loosely coupled services, improving modularity and scalability."
  },
  {
    "word": "Fibre",
    "definition": "The way Canada spells Fiber."
  },
  {
    "word": "Short",
    "definition": "In telecommunications, refers to short message service (SMS) or other forms of brief or concise communication methods."
  }
];